export const formatNumber = (q, digitos = 2) => {
    if(q!==Number){
        q=parseFloat(q);
    }
    if(isNaN(q)){
        q=0
    }
    const v=q??0;
    return Intl.NumberFormat('pt-br',{minimumFractionDigits: digitos})
          .format(v!==null?v.toFixed(digitos??2):0)
   }   

export const formatDate = (q,formato='dd/mm/yyyy') => {
  // if(isNaN(q)){
  //    return ''
  // }
  const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
  const data = new Date(q)

  if(formato==='dd/mm/yyyy hh:mm'){
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
 }

  if(formato==='dd/mm/yyyy'){
    return data.toISOString().substring(0, 10).split('-').reverse().join('/');
  }

  if(formato==='dd/mm'){
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    return `${dia}/${mes}`;    
 }      
 if(formato==='dd/MM'){
  const dia = data.getDate().toString().padStart(2, '0');
  const mes = meses[data.getMonth()];
  return `${dia}/${mes}`;
 } 
 if(formato==='dd'){
  const dia = data.getDate().toString().padStart(2, '0');
  return dia;
}
 if(formato==='MM'){
  const mes = meses[data.getMonth()];
  return mes;
 }
 if(formato==='yyyy'){
    return data.getFullYear();
 }          
}

export const  formatTelefone = (v) =>{
    if(v===null){ 
        return v
    }
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    var str = v?.toString().replace(/[^0-9]/g, "").slice(0, 11);      
    const result = str.replace(regex, "($1)$2-$3");
    return result;        
}

export const getHoje = () => {
    var today = new Date(),
    hoje = today.getFullYear() + '-' + 
          (today.getMonth() + 1).toString().padStart(2,'0') + '-' +
           today.getDate().toString().padStart(2,'0');
    return hoje
}

export const getAno = () => {
    var today = new Date(),
         ano = today.getFullYear()
    return ano
}

export const addDays = (date, days) => {
    var today = new Date(date);
        today.setDate(today.getDate() + days);
    return today.getFullYear() + '-' + 
           (today.getMonth() + 1).toString().padStart(2,'0') + '-' +
           today.getDate().toString().padStart(2,'0');
  }

 export const espacos = (g) => {
    const spc='\u00A0';
    var r='';
    for(var i=0; i<=g; i++){
        r+=spc;
    }
    return r
}

export const MesAnoPrimeiroUltimo = (mes, ano) => {
    const primeiroDia = new Date(ano, mes - 1, 1).getDate().toString().padStart(2,'0');
    const ultimoDia = new Date(ano, mes, 0).getDate().toString().padStart(2,'0');
    return { primeiroDia, ultimoDia };
}

export const LastDate = () => {
    const ultima ='2099-12-31';
    return ultima; 
}

export const formatContaDespesa = (number) => {
  let numStr = number.toString().padStart(6,'0');
  const groups = numStr.split('.').map(group => group.padStart(2, '0'));
  return groups.join('.');
}

export const formatCEP = (cep) => {
    if(!cep){
        return cep;
    }
    if (typeof cep !== 'string') {
        cep = cep.toString();
      }
    cep = cep.replace(/\D/g, '');
    cep = cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
    return cep;
}

export const formatDDDTelefone = (telefone) => {
    if(!telefone){
        return telefone;
    }
    if (typeof telefone !== 'string') {
        telefone = telefone.toString();
      }    
    telefone = telefone.replace(/\D/g, '');
    if (telefone.length === 10) {
      telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else if (telefone.length === 11) {
      telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    else if (telefone.length === 11 && telefone.startsWith('9')) {
      telefone = telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    } else if (telefone.length === 9 && telefone.startsWith('9')) {
        telefone = telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
      }
  
    return telefone;
  }

export const  inverteDate = (dataStr) => {
    const [day, month, year] = dataStr.split('/');
    return `${year}-${month}-${day}`;
}