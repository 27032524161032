import { useEffect } from "react";
import api from 'services/api';
import NAVBAR from "components/navbar";
import { useState } from "react";
import { FormataValor } from "functions/formatavalor";
import { getnome } from "functions/gettabconfig";
import { formatDate, getHoje } from "components/formatos";
import config from 'config';

function MeuExtrato(){

    const [lvenda, setLVendas] = useState([]);

    useEffect(()=>{
        api.get(config.EXTRATO)
        .then(response => {
            setLVendas(response.data);
        })
    },[])
    var tcash=lvenda.filter((x)=>x.ativocb==='A').reduce((a,e)=> a+e.saldo_cashback,0);
    var tsort=lvenda.filter((x)=>x.ativost==='A').reduce((a,e)=>a+e.saldo_sorteio,0);    

    return <div>
        <NAVBAR />
        <h1>Meu Extrato [{getnome()}] {formatDate( getHoje() )}</h1>
        <div className="row">
            <span> voce tem {FormataValor(Math.floor(tsort/100),'NUMEROD0')} cupons para participar no sorteio ou
                   pode gerar R${FormataValor(tcash,'NUMEROD2')} de desconto na rede credenciada
            </span>
        </div>
        <table className="table table-striped table-bordered">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col" className="d-none d-md-table-cell hidden-sm">Parceiro</th> 
            <th scope="col">Data</th>            
            <th scope="col">Valor</th>
            <th scope="col">Cash Back</th>            
            <th scope="col">Cupom</th>
            <th scope="col">operação</th>
            </tr>
        </thead>        
        <tbody>
            {
                lvenda.map((e,index) =>{
                    return <tr key={`vnd${index}`}>
                        <th scope="row">{e.numero_venda}</th>
                        <td className="d-none d-md-table-cell hidden-sm">{e.nomeestabelecimento}</td>
                        <td>{formatDate(e.dat_venda)}</td>
                        <td align="right">{FormataValor(e.valor_venda,'NUMEROD2')}</td>
                        <td align="right">{FormataValor(e.saldo_cashback,'NUMEROD4')}</td>
                        <td align="right">{FormataValor(e.saldo_sorteio,'NUMEROD0')}</td>
                        <td>{e.ativost==='A'?<button type="button">CUPOM</button>:<p>EXPIRADO</p>}</td>
                    </tr>           
                })   
            }            
        </tbody>
        </table>
    </div>
}

export default MeuExtrato;