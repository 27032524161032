import axios from 'axios';
import { gettoken, gettokentoken } from 'functions/gettabconfig';
import config from 'config';

const api = axios.create({
      baseURL: config.BASEAPI,
});

api.interceptors.request.use(req => {
    if(gettoken()){
        req.headers.Authorization = `Bearer ${gettokentoken()}`;
    }
    return req;
},
(err)=>{
    console.log(err);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401){
       localStorage.removeItem('eamigaconfig');
       document.location = `${config.BASENAME}/login`;
    }
    return error;
});

export default api;