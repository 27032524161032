import { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from '../../assets/close.png';
import CONFIRMACAO from "../confirmacao";
import DIALOGWAIT from "../../dialog/dialogwait";
import CABECALHOTELA from "../cabecalho";
import MESSAGE from "../message";
import './style.css';

Modal.setAppElement('#root');

function EDITMODAL(props){
    const [btndelete, setBtnDelete] = useState(false);

    useEffect(()=>{
        if (props.isOpen) {
            document.body.classList.add('modal-open');
          } else {
            document.body.classList.remove('modal-open');
          }
    },[props.isOpen])

    const habilitadelete = props.habilitadelete===undefined ? true:  props.habilitadelete ;  
    
    return<Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                overlayClassName="react-modal-overlay"
                className="react-modal-content">
                <button className="react-modal-close" 
                    type='button' 
                    onClick={props.onRequestClose}>
                    <img src={closeIcon} alt="Fechar"/>
                </button>               
                <div className="container-fluid h-100 editmodal">
                <CABECALHOTELA
                    titulo={props.programa}
                />
                <MESSAGE msg={props.mensagem} />
                <form className="row g-3 mt-2">
                    { props.children }
                </form>                
                <div className="d-flex flex-row-reverse bd-highlight mt-2">
                    <div className="col-4">
                        <button type="button" 
                                className="btn btn-lg btn-outline-primary" 
                                disabled={props.loading}
                                onClick={(e)=>props.SalvaParametro(e)}>
                                {props.loading ? <div>
                                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                            <span className="ms-2">Enviando...</span>
                                        </div>
                                        :
                                        <span className="ms-2"><i className="fas fa-save"></i>{' '}Salvar</span>}
                        </button>
                    </div>
                    {
                        (props.operacao==='UPDATE' && habilitadelete==true) ? 
                        <div className="col-4">
                            <button className="btn btn-lg btn-outline-danger" 
                                    type="button"
                                        disabled={props.loading}
                                        onClick={()=>setBtnDelete(true)} >
                                        <span><i className="fas fa-trash"></i>{' '}Excluir?</span>                                        
                                </button>
                                <CONFIRMACAO
                                        trigger={btndelete}
                                        setTrigger={setBtnDelete}
                                        title={'Confirma Exclusão'}
                                        message={'Voce realmente dese a exclusão do registro ?'}
                                        onConfirma={props.DeleteParametro}
                                        /> 
                        </div>
                        :''
                    }
                </div>
                {/* <div className="auditoria mt-2">
                    <div className="container">
                    <div className="row row-cols-4">
                        <div className="col border">Criado em:<br/>{formatDate(props.dcreate,'dd/mm/yyyy hh:mm')}</div>
                        <div className="col border">Criado por:<br/>{props.ocreate}</div>
                        <div className="col border">Alterado em:<br/>{formatDate(props.dupdate,'dd/mm/yyyy hh:mm')}</div>
                        <div className="col border">Alterado por:<br/>{props.oupdate}</div>
                    </div>
                    </div>                        
                </div> */}
            </div> 
            <DIALOGWAIT
              trigger={props.loading}              
              setTrigger={props.setLoading}           
           />
        </Modal>
}

export default EDITMODAL;