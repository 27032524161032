import './style.css';

function CARDBOX(props){
    return <div className="card">
                <div className="card-header">{props.header}
                </div>
                <div className="card-body text-center">
                    <h2 className="card-title">{props.titile}</h2>
                    <p className="card-text">{props.text}</p>
                </div>
            </div>
}

export default CARDBOX;