/*
  Estrutura do eamigaconfig:
                "id_usuario",
                "nome",
                "email",
                "whatsup",
                "dt_cadastro",
                "endereco",
                "numero",
                "complemento",
                "bairro",
                "cidade",
                "uf",
                "cep",
                "cod_cidade",
                "token"
*/
export const getidusuario = () =>
{ 
    try {   
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return tabconfig.id_usuario;
    } catch {
        return '-1';
    }
}

export const getnome = () =>
{    
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return tabconfig.nome;
}

export const getemail = () =>
{    
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return tabconfig.email;
}

export const getwhatsapp = () =>
{    
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return tabconfig.whatsup;
}

export const getcodCidade = () =>
{   
    try{
        var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
        return parseInt(tabconfig.cod_cidade);
    } catch (e){
        return 0;
    }    
}

export const gettoken = () =>
{   
    try
    {
        var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
        return tabconfig.token.length > 0;
    } catch {
        return false;
    }
}

export const gettokentoken = () =>
{   
    try
    {
        var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
        return tabconfig.token
    } catch {
        return 'invalido';
    }
}

export const getparceiro = () =>
{  
    try
    {  
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return tabconfig.parceiro;
    } catch {
        return false;
    }
}

export const getadmin = () =>
{    
    try
    { 
        var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
        return tabconfig.admin??false;
    } catch {
        return false;
    }
}

export const getenderecoAll = () =>
{    
    var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
    return `${tabconfig.endereco},${tabconfig.numero} - ${tabconfig.bairro} - ${tabconfig.cidade} - ${tabconfig.uf} - ${tabconfig.cep}`;
}

export const getestabelecimentos = (index) =>
{ 
    try {   
        var tabconfig = JSON.parse( localStorage.getItem('eamigaconfig') );
        return tabconfig.estabelecimentos[index];
    } catch {
        return 0;
    }
}