import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";
import { getHoje } from "components/formatos";
import { getnome } from "functions/gettabconfig";
import config from 'config';
import CAMERACOMPONENT from "components/cameraimagem";
import './style.css';

function AdminAvisoModal(props) {   
    const { v4: uuidv4 } = require('uuid');
    const [idaviso, setIdAviso] = useState(0);
    const [titulo, setTitulo] = useState('');
    const [corpo, setCorpo] = useState('');
    const [imagem, setImagem] = useState('');
    const [validade, setValidade] = useState(getHoje());
    const [mensagem, setMensagem] = useState(''); 
    const [loading, setLoading] = useState(false);
    

    function salvaAviso(){
        const dados = 
            {
                "type": "M",
                "title": titulo,
                "body": corpo,
                "image": `${config.BASEIMG}${imagem}`,
                "validade": validade
            } 
        setLoading(true);
        if(idaviso==0){
           api.post(`${config.ADM_AVISOS}`, dados)
            .then(response => {
                if (response.status === 202) {
                  setLoading(false);
                  props.onRequestClose();  
                } else {
                  setLoading(false);
                  setMensagem(response.response.data.erro)
                }
              })
        } else {
          api.patch(`${config.ADM_AVISOS}/${idaviso}`, dados)
            .then(response => {
            if (response.status === 202) {
              setLoading(false);
              props.onRequestClose();
            } else {
              setLoading(false);
              setMensagem(response.response.data.erro)
            }
          })
        }
        
    }

    function deletaAviso(id){
        setLoading(true);
        api.delete(`${config.ADM_AVISOS}/${idaviso}`)
        .then(response => {
        if (response.status === 202) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          setMensagem(response.response.data.erro)
        }
      })
    }

    useEffect(() => {
        if (props.dados.id_aviso != undefined) {
          setIdAviso(props.dados.id_aviso??0);
          setTitulo(props.dados.title??'');
          setCorpo(props.dados.body??'');
          setImagem((props.dados.image ?? '').replace(config.BASEIMG, ''));
          setValidade(props.dados.validade.substring(0,10));                    
        }    
      }, [props.dados])

    return  <EDITMODAL
    programa={'AVISO'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={salvaAviso}
    DeleteParametro={deletaAviso}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
    <div>
                <div className="row">
                <div className="col-md-10">
                        <label htmlFor="forID" className="form-label">ID</label>
                        <input type="text"
                            value={idaviso}
                            onChange={(e) => setIdAviso(e.target.value)}
                            disabled
                            className="form-control" id="forTitulo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forTitulo" className="form-label">Titulo</label>
                        <input type="text"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            className="form-control" id="forTitulo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forCorpo" className="form-label">Mensagem</label>
                        <textarea type="text"
                            value={corpo}
                            onChange={(e) => setCorpo(e.target.value)}
                            className="form-control" id="forCorpo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forImagem" className="form-label">Link Imagem</label>
                        <CAMERACOMPONENT
                            heigth={200}
                            width={200}
                            foto={imagem}
                            setFoto={setImagem}
                            class={'foto-aviso'}
                            base64={false}                            
                            nomedoarquivo={`avis${ uuidv4() }.png`}
                            diretorio={'imgban'}
                        />
                    
                        <input type="text"
                            value={imagem}
                            onChange={(e) => setImagem(e.target.value)}
                            className="form-control" id="forImagem" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forValidade" className="form-label">Validade</label>
                        <input type="date"
                            value={validade}
                            onChange={(e) => setValidade(e.target.value)}
                            className="form-control" id="forValidade" />
                    </div>
                </div>       
    </div>
    </EDITMODAL>
}

export default AdminAvisoModal;