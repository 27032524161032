import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Logo from '../../assets/actus-logo-48132145_1.png';
import Fundo from '../../assets/fundo-login.png';
import apibasic from 'services/apibasic';
import Footer from 'components/footer';

function SolicitaRedefinirSenha() {

    const [email, setEmail] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function Login() {
        navigate('/login');
    }

    function solicitaNovaSenha(e) {
        e.preventDefault();

        if (email === '') {
            alert('Digite seu email para solicitar uma nova senha');
            return false
        }

        setLoading(true);
        apibasic.post('v1/usuarios/solicitatrocasenha', {
            email: email,
            telefone: ''
        }).then(response => {
            if (response.status === 202) {
                setSucesso('X');
                setLoading(false);
            } else {
                setMsg(response.response.data.erro)
                setSucesso('E');
                setLoading(false);
            }
        })
            .catch(err => {
                setMsg(err.response.data.erro)
                setSucesso('E');
                setLoading(false);
            })

    }

    function FormSolicita() {
        return <form className="form-solicita mt-5">
                <img className="mb-5" src={Logo} alt="" />
                <h3 className="mb-4">Redefinir senha.</h3>
                <h6 className="mb-3">Voce recebera um e-mail para redefinir sua senha</h6>
                <div className="form-floating mb-3">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatinginput" placeholder="e-mail" />
                    <label htmlFor="floatinginput">E-mail</label>
                </div>
                {
                    <button className="w-100 btn btn-lg btn-danger" disabled={loading}
                        onClick={solicitaNovaSenha}>
                        {loading ? <div>
                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                            <span className="ms-2">Enviando...</span>
                        </div>
                            :
                            <span className="ms-2">Solicitar nova senha</span>}
                    </button>
                }
                {sucesso === 'E' ?
                    <div className="alert alert-danger mt-2"
                        role="alert">{msg}</div> : null}

                <div className="row mt-4">
                    <div className="col-12 btnselecionacidade"
                        onClick={() => Login()}>
                        <i className="fas fa-arrow-left"></i>{' '}voltar ao Login</div>
                </div>

              </form>           
    }

    function formSucess() {
        return <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-solicita mt-5">
                <div className="alert alert-info" role="alert">
                    <h4 className="alert-heading">Solicitação realizada</h4>
                    <p>Sua solicitação foi enviada ao seu e-mail, caso tenha não receba, verifique sua caixa de SPAM.</p>
                    <hr></hr>
                    <p className="mb-0">Qualquer dúvida, envie um e-mail para contato@opnet.com.br ou envie uma mensagem para WhatsApp 19-35822048.</p>
                </div>
                <img className="mt-5" src={Logo} alt="" />
            </form>
        </div>
    }


    return <div className="row">
        <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            {
                (sucesso === '' || sucesso === 'E') ? FormSolicita() : formSucess()
            }
        </div>
        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-solicita" src={Fundo} alt="ACTUS" />
        </div>
        <Footer />
    </div>
}

export default SolicitaRedefinirSenha;