// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCbsUJ-E4AFwiZQb4L72RpK3IsKAJ8oE9A",
  authDomain: "actus-f4d0e.firebaseapp.com",
  projectId: "actus-f4d0e",
  storageBucket: "actus-f4d0e.appspot.com",
  messagingSenderId: "46167380936",
  appId: "1:46167380936:web:33963d4e54c8e47201a6fd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);
let messaging;
try {
  if ("Notification" in window) {
    messaging = getMessaging(app);
  } else {
    console.warn("Notificações não são suportadas neste ambiente.");
  }
} catch (error) {
  console.error("Erro ao inicializar o Firebase", error);
}
export { messaging };

export const genarateToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log(permission)
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BCUofedoFb-0o6faKaGiPyilLHeNcRqmjGL_cy18-NWBlmpRq0LnQzBHWxRCuv1ZqdhtRVTy51jQrAi3b1WurWI'
      });
      return token
      console.log(token);
    }
    return '';
  } catch (error) {
    console.error('Erro ao gerar token', error);
    return '';
  }
}
