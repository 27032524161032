import { useEffect, useState } from 'react';
import NAVBAR from 'components/navbar';
import api from 'services/api';
import './style.css';
import config from 'config';
import CARDBOX from './components/box';
import { formatNumber } from 'components/formatos';
import FOOTER from 'components/footer';
import AvisoPopup from 'components/avisos';

function HomeAdmin() {
	const [dados, setDados] = useState([]);
	const [lcampanhas, setlCampanhas] = useState([]);
	const [lvendas, setlVendas] = useState([]);
	const [lAnos, setlAnos] = useState([]);

	useEffect(() => {
		api.get(config.ADM_DASHBOARD)
			.then(response => {
				setDados(response.data);
				let Unico = response.data.campanhas.map(g => ({ id_campanha: g.id_campanha, den_campanha: g.den_campanha }));
				Unico = Unico.filter((item, index, self) =>
					index === self.findIndex((t) => (
						t.id_campanha === item.id_campanha
					))
				);
				setlCampanhas(Unico);
				setlVendas(response.data.vendas);
				const anosUnicos = [...new Set(response.data.vendas.map(venda => venda.ano))].sort((a, b) => b - a);
				setlAnos(anosUnicos);
			});
	}, []);

	if (dados.estatisticas !== undefined) {
		return <div>
			<NAVBAR />
			<div className='mt-page'>
				<div className='linhas-card'>
					<h2>Dashboard</h2>
					<div className="row mt-2">
						<div className='col-md-2'>
							<CARDBOX
								header={'Online'}
								text={dados.estatisticas.online ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Empresas'}
								text={dados.estatisticas.num_empresa ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Ativos'}
								text={dados.estatisticas.num_ativos ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Participantes'}
								text={dados.estatisticas.num_participantes ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Usuários'}
								text={dados.estatisticas.num_usuarios ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Vendas'}
								text={dados.estatisticas.num_vendas ?? 0}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-2'>
							<CARDBOX
								header={'Campanha'}
								titile={''}
								text={dados.estatisticas.num_campanha ?? 0}
							/>
						</div>
						<div className='col-md-2'>
							<CARDBOX
								header={'Total'}
								titile={''}
								text={formatNumber(dados.estatisticas.sum_vendas)}
							/>
						</div>
					</div>
					{
						lAnos.map((ano) => (
							<div key={ano}>
								<h2>{ano}</h2>
								<div className="row mt-2">
									{lvendas.filter(v => v.ano === ano).map((v, index) => {
										return <div className='col-md-2 mb-2' key={`camp${index}`}>
											<CARDBOX
												header={v.nom_estabelecimento.substring(0, 25)}
												titile={''}
												text={<>
													<span className='linhas2-card'>Cupons: {v.nro_cupons}<br></br></span>
													<span className='linhas2-card'>Vendas: {v.nro_vendas}<br></br></span>
													<span className='linhas2-card'>Total R$ {formatNumber(v.total_venda)}<br></br></span>
													<span className='linhas2-card'>Ticket R${formatNumber(v.ticket_medio)}</span>
												</>}
											/>
										</div>
									})}
								</div>
							</div>
						))}
				</div>
			</div>
			<FOOTER />
			<AvisoPopup />
		</div>
	} else {
		<div></div>
	}

}
export default HomeAdmin;