import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './style.css';
import Logo from 'assets/ActusIcone2025x1024.png';
import Fundo from 'assets/logo.png';
import linkandroid from 'assets/google-en.png';
import linkapple from 'assets/apple-en.png';

import SaltPassword from 'services/md5';
import Footer from 'components/footer';
import apibasic from 'services/apibasic';
import { detectOS } from 'functions/detectaos';

function Login() {

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);
    const navigete = useNavigate();

    function ProcessaLogin(e) {
        e.preventDefault();

        if (email.length === 0) {
            setSucesso('E');
            return false;
        }

        if (senha.length === 0) {
            setSucesso('E');
            return false;
        }

        setSucesso('');
        setLoading(true);

        apibasic.post('/v1/login', {
            email: email,
            senha: SaltPassword(senha)
        }).then(response => {
            localStorage.setItem('eamigaconfig', JSON.stringify(
                response.data
            ));
            setLoading(false);
            setSucesso('S');
            navigete('/');
        })
            .catch(err => {
                setLoading(false);
                setSucesso('N');
            })
    }
    useEffect(() => {
        localStorage.removeItem('eamigaconfig');
    }, [])

    return <div className="row">
        <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5" onSubmit={(e) => ProcessaLogin(e)}>
                <img className="mb-3" src={Logo} alt="" width={180}/>
                <h3 className="mb-4">Beneficios para voce</h3>
                <h6 className="mb-3">Acesse seu conta</h6>
                <div className="form-floating mb-3">
                    <input type="email" onChange={(e) => setEmail(e.target.value)}
                        className="form-control" id="floatinginputemail" placeholder="e-mail" />
                    <label htmlFor="floatinginput">E-mail</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="password" onChange={(e) => setSenha(e.target.value)}
                        className="form-control" id="floatinginputpassword" placeholder="Senha" />
                    <label htmlFor="floatinginput">Senha</label>
                </div>
                <button className="w-100 btn btn-lg btn-danger"
                    disabled={loading}
                    type='submit'
                    onClick={ProcessaLogin}>
                    {loading ? <div>
                        <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                        <span className="ms-2">Enviando...</span>
                    </div>
                        :
                        <span className="ms-2">Acessar</span>}
                </button>
                {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">e-mail ou senha invalidos</div> : null}
                {sucesso === 'E' ? <div className="alert alert-danger mt-2" role="alert">e-mail ou senha vazios</div> : null}
                <hr></hr>
                <Link to="/cadastro"><button type='button' className='w-100 btn btn-lg btn-info mb-2 novocadastro'>Novo cadastro</button></Link>
                {/* <Link className='w-100 btn mb-2' to="/selecionacidade">Visitante</Link> */}
                <Link to="/solicitaredefinir"><button type='button' className='w-100 btn btn-lg btn-secundary mb-2 esqueceusenha'>Esqueceu a senha</button></Link>
                <hr></hr>
                <div className='container'>
                    <div>
                        {
                            detectOS() === 'Android' ?
                                <Link to='https://play.google.com/store/apps/details?id=br.com.opnet.actus&hl=pt_BR'>
                                    <img src={linkandroid} />
                                </Link>
                                : null
                        }
                        {
                            detectOS() === 'iOS' ?
                                <Link to='https://apps.apple.com/br/app/actus/id6503474213'>
                                    <img src={linkapple} />
                                </Link>
                                : null
                        }
                        {
                            detectOS() === 'Desconhecido' ?
                                <> <Link to='https://play.google.com/store/apps/details?id=br.com.opnet.actus&hl=pt_BR'>
                                    <img src={linkandroid} />
                                </Link><Link to='https://apps.apple.com/br/app/actus/id6503474213'>
                                        <img src={linkapple} />
                                    </Link></>
                                : null
                        }

                    </div>
                </div>
            </form>

        </div>

        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-login" src={Fundo} alt="Opnet Mais" />
        </div>
        <Footer />
    </div>
}

export default Login;