import CAMERACOMPONENT from "components/cameraimagem";

function EDITFOTOS(props){
    const addImagem = () =>{
        var lista = [...props.fotos];
        lista.push( {'url_foto': `/users/foto${lista.length}.png`});
        props.setFotos(lista);
    }
    const removImagem = (index) => {
        let lista =props.fotos.filter((e, i) => i !== index);
        props.setFotos(lista);   
    }
    const setValorImagem = (img,index,campo) => {
        console.log('setValorImagem',index,campo)
        let lista = [...props.fotos];
        lista[index][campo]=`/users/sort${props.idsorteado}_${index}.png`
        props.setFotos(lista);  
        console.log('lista',lista) 
    }        
    return <>
        <div className="listaimagens">
            <div className="col-md-1">
                    <button className="btn btn-lg btn-outline-primary"
                        type="button"                      
                        onClick={(e) => addImagem()} >
                        <span><i className="fas fa-plus"></i></span>
                    </button> 
            </div>
            {
                props.fotos.map((e,index) =>{
                    return <div key={`limg${index}`} className="itemimg stk30det">
                    <CAMERACOMPONENT
                        heigth={512}
                        width={512}
                        foto={e.url_foto}
                        setFoto={setValorImagem}
                        className={'stk30det'}
                        index={index}
                        campo={'url_foto'}
                        nomedoarquivo={`sort${props.idsorteado}_${index}.png`}
                        diretorio={'users'}
                    />
                    <button className="btn btn-lg btn-outline-danger"
                        type="button"                      
                        onClick={(e) => removImagem(index)} >
                        <span><i className="fas fa-trash"></i></span>
                    </button> 
                    </div>
                })
            }
        </div>    
    </>
}

export default EDITFOTOS;