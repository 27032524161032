import { useState } from "react";
import Modal from "react-modal";
import closeIcon from '../../../assets/close.png';
import './style.css';
import api from 'services/api';
import SaltPassword from 'services/md5';

Modal.setAppElement('#root');

function AlterarSenhaModal(props){

    const [senhaatual, setSenhaAtual] = useState('');
    const [novasenha, setNovaSenha] = useState('');
    const [confirmasenha, setConfirmaSenha] = useState('');
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgErro, setMsgErro] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    
    function ExibeMensagem(){
        setDisabled(true);
        setMsg('Senha alterada com sucesso');
        setTimeout(()=>{
            setMsg('')            
            props.onRequestCloseSenha();
        },5000);        
    }

    function ExibeErro(msgErro){
        setMsgErro(msgErro);
        setTimeout(()=>setMsgErro(''),3000);
    } 
    
    function setConfere(e){
        setConfirmaSenha(e.target.value);
        var s=e.target.value;
        if(novasenha!==''){
            setDisabled(s!==novasenha);
        }else{
            setDisabled(true);
        }
    }

    function AlterarSenha(){
        if(confirmasenha !== novasenha){
            ExibeErro('Senha não confere');            
        }else{
        /*const dados={
            email: props.email,
            senha: senhaatual.length > 0 ? SaltPassword(senhaatual): '',
            novasenha: novasenha.length > 0 ? SaltPassword(novasenha): ''
        }*/
         setLoading(true);

         api.post('/v1/adm/usuarios/trocasenha',{
                email: props.email,
                senha: senhaatual.length > 0 ? SaltPassword(senhaatual): '',
                novasenha: novasenha.length > 0 ? SaltPassword(novasenha): ''
            })
            .then(response => {
                if(response.status === 200){                                        
                    //alert('Senha alteradoa com sucesso');
                    ExibeMensagem();                          
                } else {
                    setLoading(false);
                    console.log(response.statusText);
                    ExibeErro('Ocorreu um erro no cadastro ' + response.statusText);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                ExibeErro(err);            
            });
            setLoading(false);
        }
    }
    /*useEffect(()=>{
        setLoading(false);
        setDisabled(true);
     },[]);*/
    
    return <Modal isOpen={props.isOpen} 
                  onRequestClose={props.onRequestCloseSenha}
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">

                <button className="react-modal-close" type='button' 
                    onClick={props.onRequestCloseSenha}> <img src={closeIcon} alt="Fechar"/>
                </button>         

                <div className="container-fluid h-100 perfil-senha">
                <div className="row m-2">
                        <h3>Alterar Senha para {props.email}</h3>
                    </div> 
                    <div className="row m-2">
                        
                            <div className="mb-3">
                                <label htmlFor="InputSenhaAtual" className="form-label">Senha atual</label>
                                <input type="password" onChange={(e)=> setSenhaAtual(e.target.value)} 
                                className="form-control" id="InputSenhaAtual" aria-describedby="senhaatual" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputNovaSenha" className="form-label">Nova senhas</label>
                                <input type="password" onChange={(e)=> setNovaSenha(e.target.value)}
                                className="form-control" id="InputNovaSenha" aria-describedby="novasenha" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputConfirmaSenha" className="form-label">Confirma nova senha</label>
                                <input type="password" onChange={(e)=>setConfere(e)}
                                className="form-control" id="InputConfirmaSenha" aria-describedby="confirmasenha" />
                            </div>                        
                            <div className="row mb-3">
                                <button className="w-100 btn btn-lg btn-danger" disabled={loading || isDisabled}
                                        onClick={AlterarSenha}>
                                        {loading ? <div>
                                                    <span className="spinner-border spinner-border-sm text-light" 
                                                        role="status"></span>
                                                    <span className="ms-2">Aterando senha...</span>
                                                </div>
                                                :
                                                <span className="ms-2">Alterar Senha</span>}
                                </button>
                            </div>
                            {
                            msg.length>0 ? <div className="alter alert-infos mt-4 text-center">{msg}</div>: null
                            }
                            {
                            msgErro.length>0 ? <div className="alter alert-danger mt-4 text-center">{msgErro}</div>: null
                            }                        
                        
                    </div>                    
                
                </div>
</Modal>
}

export default AlterarSenhaModal;