import POPUP from "components/popup";
import './style.css';

function DIALOGWAIT(props){
    function Close(){        
        props.setTrigger(false);
    }

    return <POPUP
            trigger={props.trigger}
            setTrigger={Close}>
            <h1>Aguarde...</h1>
            <div className="wait-container">                
                <div className="spinner-border text-dark" role="status">
                   <span className="sr-only">Aguarde...</span>
                </div>
            </div>
    </POPUP>    
}

export default DIALOGWAIT;