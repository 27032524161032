export const FormataValor = (valor,css) => {   
    if(css==='NUMEROD0'){
        return new Intl.NumberFormat('pt-br',{minimumFractionDigits: 0}).format(valor)
    }
    if(css=='NUMEROD2'){
        return new Intl.NumberFormat('pt-br',{minimumFractionDigits: 2}).format(valor)            
    }
    if(css=='NUMEROD4'){
        return new Intl.NumberFormat('pt-br',{minimumFractionDigits: 4}).format(valor)
    }  
    return valor            
  }

  export const cepMask = (value) => {
    if (!value) return ""    
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{5})(\d)/,'$1-$2')
    return value
  }