export const detectOS= () =>{
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  } else if (/win/i.test(userAgent)) {
    return 'Windows';
  } else if (/android/i.test(userAgent)) {
    return 'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } else if (/mac/i.test(userAgent)) {
    return 'MacOS';
  } else if (/linux/i.test(userAgent)) {
    return 'Linux';
  } else {
    return 'Desconhecido';
  }
  }

  window.onload = () => {
    const sistemaOperacional = detectOS();
    //console.log('Sistema Operacional:', sistemaOperacional);
  };