import config from 'config';

function IMAGEM(props){
    if(!props.participantes){       
        var BASEIMAGEM=`${config.BASEIMG}${props.src}`;
    } else {
        var BASEIMAGEM=props.src; 
    }
    if(props.className){
        var CLASS = `rounded mx-auto d-block ${props.className}`;
    }else{
        var CLASS = `rounded mx-auto d-block`;
    }

    return <img src={BASEIMAGEM} className={CLASS} width={props.width} />
}

export default IMAGEM;