import config from 'config';
import IMAGEM from '../imagem';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { formatDate } from 'components/formatos';

const Contemplados = ({banner,nome,nome_sorteado, nome_estabelecimento, limages, datasorteio})  =>{   
   var llimages = [...limages];

    return <div className="custom-card-contemplado cardestab card justify-content-center" >
    <div className='d-block mt-2'>
    <Carousel>
          {llimages.map((i,index) => (
            <Carousel.Item key={`cont${index}`}>
              <div className="custom-card-header-contemplado">                
                    <IMAGEM 
                        participantes={true}
                        src={`${config.BASEIMG}${i.url_foto}`} 
                        width={180} 
                        className={"card-img-top-contemplado shadow-image-contemplado"}
                        alt={i.url_foto} />
                  </div>
            </Carousel.Item>
          ))}
        </Carousel>
    </div>
    <div className="card-body-contemplado">
      <h5 className="card-title-contemplado">{nome_sorteado}</h5> 
      <p>{formatDate( datasorteio )} </p>     
    </div>
  </div>
}

export default Contemplados;