import { useEffect, useState } from 'react';
import FOOTER from 'components/footer';
import NAVBAR from 'components/navbar';
import './style.css';
import Contemplados from 'components/contemplados';
import api from 'services/api';
import { getcodCidade, getnome } from 'functions/gettabconfig';
import { useNavigate } from 'react-router-dom';
import config from 'config';
import CarrosselCampanhas from 'components/carrosselCampanha';
import CarrosselParticipantes from 'components/carrosselParticipantes';
import Conquistas from 'components/conquitas';
import CarrosselContemplados from 'components/carrosselContemplados';
import AvisoPopup from 'components/avisos';

function Principal(){

    const navigate = useNavigate();

    const [lcompanha, setLCampanha] = useState([]);
    const [lparticipante, setLParticipante] = useState([]);
    const [lcontemplado, setLContemplado] = useState([]);

    function Conquista(){
        navigate('/meu/conquista')
    }
    
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    useEffect (()=>{
        // Busca Campanhas
        api.get(config.CAMPANHAS)
        .then( response => {
            setLCampanha(response.data);
        })
        .catch (error =>{
            console.log(error);
        });
        // Busca Partificantes
        api.get(`${config.ESTABELECIMENTOS}?cod_cidade=${getcodCidade()}`)
        .then( response => {
            setLParticipante(shuffleArray(response.data));
        })
        .catch (error =>{
            console.log(error);
        });    
        // Busca Contemplados
        api.get(`${config.CONTEMPLADOS}?cod_cidade=${getcodCidade()}`)
        .then( response => {
            setLContemplado(shuffleArray(response.data));
        })
        .catch (error =>{
            console.log(error);
        });     
    },[])

    return <>   

    <div className="container-fluid mt-page">
        <NAVBAR />
        

        <Conquistas 
           Conquista={Conquista}
           getnome={getnome()}
        />
        
        <CarrosselParticipantes
            lparticipante={lparticipante}
        />
         <CarrosselCampanhas
            lcompanha={lcompanha}
         />

        <CarrosselContemplados 
          lcontemplado={lcontemplado} />

       
        
        <div className='mb-10'>
        
        </div>
        <FOOTER />
    </div>
    <AvisoPopup />
</>
}

export default Principal;