import './style.css';
import IMAGEM from '../imagem';

function Cupom(props) {

  const date = new Date(props.dtEmissao)

  return <div className="cupom" key={props.idCupom}>
             <div className="cupom-images">
              <IMAGEM src={props.banner} width={100} className="banner-image" />
              <IMAGEM src={props.logo} width={50} className="logo-image" />
            </div>
            <div className="cupom-content">
              <h4 className="cupom-estab">{props.nom_estabelecimento}</h4>
              <h4 className="cupom-title">{props.den_campanha}</h4>
              <p className="cupom-subtitle">{`Cupom cód: ${props.idCupom}`}</p>
              
            </div>
  </div>

}

export default Cupom;