import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Contemplados from '../contemplados'
import Cupom from '../cupom';

const CarrosselCupons = ({ lcupons }) => {
  const responsive = {
    superLargeDesktop: {
      // screens larger than 1920px
      breakpoint: { max: 4000, min: 1920 },
      items: 4
    },
    desktop: {
      // screens larger than 1024px
      breakpoint: { max: 1920, min: 1024 },
      items: 3
    },
    tablet: {
      // screens larger than 464px
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      // screens smaller than 464px
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  return (
    <>
      {lcupons.length > 0 && (
        <div className='row'>
          <h1>Cupons</h1>
          <Carousel responsive={responsive} infinite={true} className="mt-5">
            {lcupons.map((c, index) => (
              <div key={`cupons${index}`} className="p-2">
                <Cupom key={c.id_sorteio}
                            idCupom={c.id_sorteio}
                            dtEmissao={c.dat_emissao}
                            id_campanha={c.id_campanha}
                            den_campanha={c.den_campanha}
                            banner={c.banner}
                            id_estabelecimento={c.id_estabelecimento}
                            nom_estabelecimento={c.nom_estabecimento}
                            logo={c.url_logo}
                        />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default CarrosselCupons;
