import './style.css';

function POPUP(props){
    return (props.trigger)?(
        <div className="popup">
            <div className="popup-inner">
                <div className="close-brn" 
                     onClick={()=>props.setTrigger(false)}>
                    <i className="fas fa-window-close"></i>
                </div>
                { props.children }
            </div>
        </div>
    ) : "" ;
}

export default POPUP;