import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Participantes from '../participantes';


const CarrosselParticipantes = ({ lparticipante }) => {
  const responsive = {
    superLargeDesktop: {
      // screens larger than 1920px
      breakpoint: { max: 4000, min: 1920 },
      items: 4
    },
    desktop: {
      // screens larger than 1024px
      breakpoint: { max: 1920, min: 1024 },
      items: 4
    },
    tablet: {
      // screens larger than 464px
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    mobile: {
      // screens smaller than 464px
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };
  

  return (
    <div className='mt-3'>
      <h1 id="Participantes">Lojas Participas</h1>
      <Carousel responsive={responsive} infinite={true} className="mt-3">
        {lparticipante.map(b => (          
          <div key={`estb${b.id_estabelecimento}`} className='p-2'>            
            <Participantes
              nome={b.nome}
              endereco={`${b.endereco},${b.complemento}`}
              telefone={b.telefone}
              whatsup={b.whatsup}
              foto={b.url_foto}
              logo={b.url_logo}
              site={b.site}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarrosselParticipantes;
