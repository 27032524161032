import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import Rotas from './rotas';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <Rotas />
  //</React.StrictMode>
);
