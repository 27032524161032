import config from 'config';
import IMAGEM from '../imagem';
import './style.css';

const Participantes = ({ nome, endereco, telefone, whatsup, foto, logo, site }) => {
  return (
      <a 
          href={site} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="custom-card cardestab card justify-content-center text-decoration-none"
          style={{ color: 'inherit' }} // Preserva as cores originais do card
      >
          <div className="d-block mt-2">
              <div className="custom-card-header">
                  <IMAGEM 
                      participantes={true}
                      src={`${config.BASEIMG}${foto}`} 
                      width={100}                       
                      className="card-img-top shadow-image" 
                      alt={nome} 
                  />
              </div>
          </div>
          <div className="card-body">
              <h5 className="card-title">{nome}</h5>
              {telefone==whatsup 
                ?  <p className="card-text">{telefone}</p>
                :   telefone!='' && whatsup!='' ?
                    <p className="card-text">{`${telefone} ${whatsup}`}</p>
                    : telefone!='' && whatsup==='' ?
                       <p className="card-text">{`${telefone}`}</p>
                       : telefone=='' && whatsup!='' ?  
                         <p className="card-text">{`${whatsup}`}</p>
                        : <p className="card-text"></p>}
          </div>
      </a>
  );
};


export default Participantes;
