import './style.css';

function CABECALHOTELA(props){
    return <div className="container-cab">
                    <div className="left-cab"><h4>{props.titulo}</h4></div>
                    <div className="right-cab"> {props.children}</div>
           </div>          
           
}

export default CABECALHOTELA;