import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

const CustomProgressBar = ({ progress, label }) => {
  const [customStyle, setCustomStyle] = useState({
    height: '50px',
    backgroundColor: '#6c757d', // Cor padrão
  });

  // Função para atualizar o estilo com base no progresso
  const updateStyle = () => {
    const newColor = progress > 50 ? 'green' : 'red';
    setCustomStyle({
      ...customStyle,
      backgroundColor: newColor,
    });
  };

  // Atualizar o estilo ao montar o componente
  //updateStyle();

  return (
    <ProgressBar
      now={progress}
      label={label}
      variant="custom"
      style={customStyle}
    />
  );
};

export default CustomProgressBar;