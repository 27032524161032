import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";

import './style.css';
import { getHoje } from "components/formatos";
import { getnome } from "functions/gettabconfig";
import config from 'config';
import Tabs from "components/tabs/tabs";
import CAMERACOMPONENT from "components/cameraimagem";
import LISTAESTABELECIMENTOS from "./listaEstabelecimento";

function AdminCampanhaModal(props) {

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const [idcampanha, setIDCampanha] = useState(0);
  const [dencampanha, setDenCampanha] = useState('');
  const [validadeini, setValidadeIni] = useState(getHoje());
  const [validadefim, setValidadeFim] = useState(getHoje());
  const [banner, setBanner] = useState('');
  const [lestabelecimento, setLEstabelecimento] = useState([]);

  function Salva() {
    setMensagem('');
    setLoading(true);
    var participante = lestabelecimento.filter((e) => e.id_campanha > 0);
    const dados = {
      "id_campanha": idcampanha,
      "den_campanha": dencampanha,
      "validade_ini": validadeini,
      "validade_fim": validadefim,
      "banner": banner,
      "estabelecimentos": participante
    }
    if (idcampanha > 0) {
      api.patch(`${config.ADM_CAMPANHA}/${idcampanha}`, dados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.error)
          }
        })
    } else {
      api.post(config.ADM_CAMPANHA, dados)
        .then(response => {
          if (response.status === 201) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.error)
          }
        })
    }
  }

  function Deleta() {
    setMensagem('');
    setLoading(true);
    api.delete(`${config.ADM_CAMPANHA}/${idcampanha}`)
      .then(response => {
        if (response.status === 202) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          setMensagem(response.response.data.error)
        }
      })

  }

  function BuscaEstabelecimentos(id) {
    api.get(`${config.ADM_CAMPANHA}/estabelecimentos/${id}`)
      .then(response => {
        setLEstabelecimento(response.data);
      })
      .catch((err) => {
        setLEstabelecimento([]);
        console.log(err);
      })
  }

  function selecionaEstabelecimento(id, index) {
    const l = [...lestabelecimento];
    l[index].id_campanha = id;
    setLEstabelecimento(l);
  }



  useEffect(() => {
    if (props.dados.id_campanha != undefined) {
      setIDCampanha(props.dados.id_campanha);
      setDenCampanha(props.dados.den_campanha);
      setValidadeIni(props.dados.validade_ini);
      setValidadeFim(props.dados.validade_fim);
      setBanner(props.dados.banner);
      // Carregar Lista de Estabelecimentos....
      BuscaEstabelecimentos(props.dados.id_campanha);
    }

  }, [props.dados])


  return <EDITMODAL
    programa={'CAMPANHA'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={Salva}
    DeleteParametro={Deleta}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
  <Tabs>
    <div label="Campanha">
      <div className="row">
      <div className="col-md-4">
        <label htmlFor="selectCampanha" className="form-label">Campanha</label>
        <input type="text"
          value={idcampanha}
          onChange={(e) => setIDCampanha(e.target.value)}
          disabled
          className="form-control" id="inputEstado" />
      </div>
      <div className="col-md-6">
        <label htmlFor="inputDescricao" className="form-label">Descricao</label>
        <input type="text"
          value={dencampanha}
          onChange={(e) => setDenCampanha(e.target.value)}
          className="form-control" id="inputDescricao" />
      </div>
      <div className="col-md-6">
        <label htmlFor="inputInicio" className="form-label">Inicio</label>
        <input type="date"
          value={validadeini}
          onChange={(e) => setValidadeIni(e.target.value)}
          className="form-control" id="inputInicio" />
      </div>
      <div className="col-md-6">
        <label htmlFor="inputFim" className="form-label">Final</label>
        <input type="date"
          value={validadefim}
          onChange={(e) => setValidadeFim(e.target.value)}
          className="form-control" id="inputFim" />
      </div>
      <div className="col-md-12">
        <label htmlFor="inputBanner" className="form-label">Banner</label>
        <input type="text"
          value={banner}
          //onChange={(e) => setBanner(e.target.value)}
          className="form-control" id="inputBanner" />
      </div>
    </div>
    </div>
    
    <div label="Participantes">
       <LISTAESTABELECIMENTOS
       idcampanha={idcampanha}
       lestabelecimento={lestabelecimento}
       setLEstabelecimento={setLEstabelecimento}
       />
    </div>
    <div label="Imagem">
        <CAMERACOMPONENT
              heigth={512}
              width={512}
              foto={banner}
              setFoto={setBanner}
              className={'foto'}
              base64={false}
              //setImgB64={setB64Foto}
              nomedoarquivo={`ban${idcampanha}.png`}
              diretorio={'imgban'}
            />
    </div>
  </Tabs>   
  </EDITMODAL>
  
}

export default AdminCampanhaModal;