import './style.css';
/*
<SELECT
  key=
  name=
  SalvaSelect=
  value=
  chave=
  descricao=
  aoptions=
  retornoe=Se true retorno o elemente (e) caso constrario retorna  value do elemento
/>
*/

function SELECT(props) {
  function setValor(e) {
    if (props.retornoe) {
      props.SalvaSelect(e);
    } else {
      const index = props.index !== undefined && props.index != null ? props.index : -1;
      if (index > -1) {
        const campo = props.campo !== undefined && props.campo != null ? props.campo : '-1';
        if (campo === '-1') {
          props.SalvaSelect(e.target.value, index);
        } else {
          props.SalvaSelect(e.target.value, index, campo);
        }
      } else {
        props.SalvaSelect(e.target.value);
      }
    }
  }
  const desabilita = !props.disabled? false:props.disabled;
  return <select key={'Select' + props.name} className={props.className??'form-select'}
      name={'Select' + props.name}
      id={'Select' + props.name}
      onChange={setValor}
      value={props.value??''}
      disabled={desabilita}>
      {
        props.aoptions.map((c, index) => {
          return <option key={'SelectOption' + c[props.chave] + index}
            value={c[props.chave]}>
            {c[props.descricao]}
          </option>
        })
      }
    </select>  
}

export default SELECT;
