import POPUP from '../popup';

function CONFIRMACAO(props) {

  function CloseConfirmacao() {
    props.setTrigger(false);
  }

  function Confirmacao() {
    props.onConfirma();
    CloseConfirmacao();
  }

  return <POPUP
    trigger={props.trigger}
    setTrigger={CloseConfirmacao}>
    <div className="form-control">
      <div className="row">
        <h3>{props.title}</h3>
        <hr />
        <h6>{props.message}</h6>
        <div className="modal-footer">
          <div className="col-3">
            <div className="btn btn-danger" onClick={() => props.setTrigger(false)}>Cancela</div>
          </div>
          <div className="col-3">
            <div className="btn btn-primary" onClick={() => Confirmacao()}>Confirma</div>
          </div>
        </div>
      </div>
    </div>
  </POPUP>
}

export default CONFIRMACAO;