import { useEffect, useState } from "react";
import NAVBAR from "components/navbar";
import api from "services/api";
import AdminEstabelecimentoModal from './modal';
import './style.css';
import config from 'config';
import { getadmin, getcodCidade, getestabelecimentos } from "functions/gettabconfig";
import FOOTER from "components/footer";

function AdminEstabelecimento() {
    const [lEstabelecimento, setLEstabelecimento] = useState([]);
    const [idestabelecimento, setIDEstabelecimento] = useState(getestabelecimentos(0));
    const [admin, setAdmim] = useState(getadmin());
    const [search, setSearch] = useState('');

    // Modal
    const [operacao, setOperacao] = useState('')
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function closeAdminEstabelecimentoModal() {
        setIsOpen(false);
    }

    function novoCadastro() {
        const ldata = {
            'id_estabelecimento': 0,
            'nome': '',
            'url_foto': '',
            'url_logo': '',
            'avaliacao': 0,
            'id_categoria': 0,
            'id_cupom': null,
            'endereco': '',
            'complemento': '',
            'bairro': '',
            'cidade': '',
            'cep': '',
            'uf': '',
            'cod_cidade': getcodCidade(),
            'ind_ativo': 'N',
            'qtd_avaliacao': 0,
            'vl_min_pedido': 0,
            'vl_taxa_entrega': 0,
            'telefone': '',
            'whatsup': ''
        }
        setDados(ldata);
        setIsOpen(true);
    }

    useEffect(() => {
        if (admin) {
            searchEstabelecimento();            
        } else {
            api.get(`${config.ADM_ESTABELECIMENTOS}/${idestabelecimento}`)
                .then(response => {
                    setLEstabelecimento(response.data);
                })

        }
        
    }, [isOpen])

    function editarEstabelecimento(linha) {
        api.get(`${config.ADM_ESTABELECIMENTOS}/${linha.id_estabelecimento}`)
            .then(response => {
                setOperacao('UPDATE')
                setDados(response.data[0]);
                setIsOpen(true);
            }).catch(err => {
                console.log(err)
                //document.location = `/empresaamiga/erro?m=No momento não conseguimos alcançar o servidor`
            })
    }
    function KeyDown(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            searchEstabelecimento();
        }
    }
    async function searchEstabelecimento() {
        try {
            const response = await api.get(`${config.ADM_ESTABELECIMENTOS}`);
            const listaall = response.data;
            const lista = listaall.filter(f => {
                const matchNumeroId = f.id_estabelecimento.toString().includes(search);
                const matchNome = f.nome.toLowerCase().includes(search.toLowerCase());
                return matchNumeroId || matchNome;  // Considera correspondência em um ou outro campo
            });
            setLEstabelecimento(lista);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    }

    return <div>
        <NAVBAR />
        <AdminEstabelecimentoModal isOpen={isOpen}
            onRequestClose={closeAdminEstabelecimentoModal}
            operacao={operacao}
            dados={dados}
            habilitadelete={admin}
        />
        <div className="mt-page">
            <h1>Estabelecimentos</h1>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label htmlFor="floatinginputsearch" className="form-label">Pesquisa</label>
                    <div className="input-group rounded">
                        <input type="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={KeyDown}
                            autoFocus={true}
                            className="form-control"
                            id="floatinginputsearch"
                            placeholder="Pesquisa estabelecimento" />
                        <button className="btn btn-large"
                            type="button"
                            onClick={() => searchEstabelecimento()}>
                            <i className="fas fa-search" /></button>
                    </div>
                </div>
            </div>
            <div className="table-container">
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col"># {admin && <button type="button" onClick={() => novoCadastro()} className="btn btn-info">Novo</button>}</th>
                        <th scope="col" className="d-none d-md-table-cell hidden-sm">Nome</th>
                        <th scope="col">operação</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lEstabelecimento.map((e, index) => {
                            return <tr key={`vnd${index}`}>
                                <th scope="row">{e.id_estabelecimento}</th>
                                <td className="d-none d-md-table-cell hidden-sm">{e.nome}</td>
                                <td><button className='btn btn-danger'
                                    type="button"
                                    onClick={() => editarEstabelecimento(e)}><i className="fas fa-edit"></i></button></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            </div>
        </div>
        <FOOTER />
    </div>
}

export default AdminEstabelecimento;