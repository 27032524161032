import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";
import { getHoje } from "components/formatos";
import { getnome } from "functions/gettabconfig";
import config from 'config';
import Tabs from "components/tabs/tabs";
import EDITFOTOS from "./listafotos";
import './style.css';
function AdminContempladoModal(props) {

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [idsorteado, setIDSorteado]=useState(0);
  const [idsorteio, setIDSorteio] = useState(0);
  const [idusuario, setIDUsuario]=useState(0);
  const [idestabelecimento, setIDEstabelecimento]=useState(0);
  const [datasorteio, setDataSorteio]=useState('');
  const [nomesorteado, setNomeSorteado]=useState('');
  const [idcampanha, setIdCampanha]=useState(0);
  const [dencampanha, setDenCampanha]=useState('');
  const [nomestabelecimento, setNomeEstabelecimento]=useState('');
  const [nomevendedor, setNomeVendedor]=useState('');
  const [idvendedor, setIDVendedor]=useState(0);
  const [datavenda, setDataVenda] = useState('');
  const [numerovenda, setNumeroVenda] = useState('');
  const [valorvenda, setValorVenda] = useState(0);
  const [lfotos, setLFotos]=useState([]);

  
  function Salva() {
    setMensagem('');
    setLoading(true);
    //var participante = lestabelecimento.filter((e) => e.id_campanha > 0);
    const dados = {      
        "id_sorteado": idsorteado,
        "id_sorteio": idsorteio,
        "id_usuario": idusuario,
        "id_campanha": idcampanha,
        "id_estabelecimento": idestabelecimento,
        "data_sorteio": datasorteio,
        "nome_sorteado": nomesorteado,
        "id_vendedor": idvendedor,
        "nome_vendedor": nomevendedor,
        "fotos": lfotos    
    }
    if (idsorteado > 0) {
      api.patch(`${config.ADM_CONTEMPLADOS}/${idsorteado}`, dados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.error)
          }
        })
    } else {
      api.post(config.ADM_CONTEMPLADOS, dados)
        .then(response => {
          if (response.status === 201) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.error)
          }
        })
    }
  }

  function Deleta() {
    setMensagem('');
    setLoading(true);
    api.delete(`${config.ADM_CONTEMPLADOS}/${idsorteado}`)
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          
        }
      })

  }
  
  useEffect(() => {
    if (props.dados.id_sorteado != undefined) {      
      setIDSorteado(props.dados.id_sorteado ?? 0);
      setIDSorteio(props.dados.id_sorteio ?? 0);
      setIDUsuario(props.dados.id_usuario ?? 0);
      setIDEstabelecimento(props.dados.id_estabelecimento ?? 0);
      setIdCampanha(props.dados.id_campanha ?? 0);
      setDenCampanha(props.dados.den_campanha ??'');
      setNomeEstabelecimento(props.dados.nom_estabelecimento ?? '');
      setNomeSorteado(props.dados.nome_sorteado ?? '');
      setDataSorteio(props.dados.data_sorteio ? props.dados.data_sorteio.substring(0,10) : getHoje());
      setIDVendedor(props.dados.id_vendedor ?? 0);
      setNomeVendedor(props.dados.nom_vendedor?? '');
      setDataVenda(props.dados.data_venda ? props.dados.data_venda.substring(0,10) : getHoje());
      setNumeroVenda(props.dados.numero_venda ?? '0');
      setValorVenda(props.dados.valor_venda ?? 0);
      setLFotos(props.dados.fotos ?? []);      
    }

  }, [props.dados])


  return <EDITMODAL
    programa={'CONTEMPLADO'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={Salva}
    DeleteParametro={Deleta}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
  <Tabs>
    <div label="Contemplado">
      <div className="row">
        <div className="col-md-2">
          <label htmlFor="selectNumero" className="form-label">Numero</label>
          <input type="text"
            value={idsorteado}
            onChange={(e) => setIDSorteado(e.target.value)}
            disabled
            className="form-control" id="selectNumero" />
        </div> 
        <div className="col-md-2">
        <label htmlFor="selectCupom" className="form-label">Cupom</label>
          <input type="text"
            value={idsorteio}
            onChange={(e) => setIDSorteio(e.target.value)}
            disabled
            className="form-control" id="selectCupom" />
        </div> 
        <div className="col-md-2">
          <label htmlFor="selectIdEsta" className="form-label">Estabelecimento</label>
            <input type="text"
              value={idestabelecimento}
              onChange={(e) => setIDEstabelecimento(e.target.value)}
              disabled
              className="form-control" id="selectIdEsta" />
          </div> 
          <div className="col-md-6">
          <label htmlFor="selectNomEsta" className="form-label">Estabelecimento</label>
            <input type="text"
              value={nomestabelecimento}
              onChange={(e) => setNomeEstabelecimento(e.target.value)}
              disabled
              className="form-control" id="selectIdEsta" />
          </div>
          <div className="col-md-2">
          <label htmlFor="selectIdUsuario" className="form-label">Usuario</label>
            <input type="text"
              value={idusuario}
              onChange={(e) => setIDUsuario(e.target.value)}
              disabled
              className="form-control" id="selectIdUsuario" />
          </div> 
          <div className="col-md-10">
          <label htmlFor="selectNomUsuario" className="form-label">Nome</label>
            <input type="text"
              value={nomesorteado}
              onChange={(e) => setNomeSorteado(e.target.value)}
              disabled
              className="form-control" id="selectIdUsuario" />
          </div> 
          <div className="col-md-12">
          <label htmlFor="selectDadosUsuario" className="form-label">Endereço Usuário</label>
            <input type="text"
              value={`${props.dados.endereco??'Endereço '}, ${props.dados.numero??' Numero'}`}
              onChange={(e) => setNomeSorteado(e.target.value)}
              disabled
              className="form-control" id="selectIdUsuario" />
          </div>
          
        <div className="col-md-3">
          <label htmlFor="DataSort" className="form-label">Data Sorteio</label>
            <input type="date"
              value={datasorteio}
              onChange={(e) => setDataSorteio(e.target.value)}
              disabled
              className="form-control" id="DataSort" />          
        </div> 

        <div className="col-md-3">
          <label htmlFor="DataSort" className="form-label">Data venda</label>
            <input type="date"
              value={datavenda}
              onChange={(e) => setDataVenda(e.target.value)}
              disabled
              className="form-control" id="DataSort" />          
        </div> 
        <div className="col-md-3">
          <label htmlFor="NumeroVenda" className="form-label">Numero venda</label>
            <input type="text"
              value={numerovenda}
              onChange={(e) => setNumeroVenda(e.target.value)}
              disabled
              className="form-control" id="NumeroVenda" />          
        </div> 
        <div className="col-md-3">
          <label htmlFor="ValorVenda" className="form-label">Valor Venda</label>
            <input type="text"
              value={valorvenda}
              onChange={(e) => setValorVenda(e.target.value)}
              disabled
              className="form-control" id="NumeroVenda" />          
        </div> 
        <div className="col-md-5">
          <label htmlFor="denCampanha" className="form-label">Campanha</label>
            <input type="text"
              value={dencampanha}
              onChange={(e) => setDenCampanha(e.target.value)}
              disabled
              className="form-control" id="idCampanha" />          
        </div>

        <div className="col-md-3">
          <label htmlFor="idvendedor" className="form-label">ID Vendedor</label>
            <input type="text"
              value={idvendedor}
              onChange={(e) => setIDVendedor(e.target.value)}
              disabled
              className="form-control" id="idvendedor" />          
        </div>
        <div className="col-md-4">
          <label htmlFor="nomevendedor" className="form-label">Vendedor</label>
            <input type="text"
              value={nomevendedor}
              onChange={(e) => setNomeVendedor(e.target.value)}
              disabled
              className="form-control" id="nomevendedor" />          
        </div>
        </div>
    </div>
    
    <div label="Fotos">
        <EDITFOTOS
          idsorteado={idsorteado}
          fotos={lfotos}
          setFotos={setLFotos}
        />
    </div>
  </Tabs>   
  </EDITMODAL>
  
}

export default AdminContempladoModal;