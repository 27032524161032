import { useEffect, useState } from "react";
import EDITMODAL from 'components/editmodal';
import api from "services/api";

import './style.css';
import { getAno, getHoje } from "components/formatos";
import { getnome } from "functions/gettabconfig";
import config from 'config';
import SELECT from "components/select";

function AdminSORTEIO(props) {

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [lcampanhas, setLCampanhas] = useState([]);
  const [idcampanha, setIDCampanha] = useState(-1);
  const [numeroSorteado, setNumeroSorteado] = useState('SORTEANDO...');
  const [sorteando, setSorteando] = useState(false);

  const [sorteado, setSorteado] = useState(false);

  const [idsorteado, setIDSorteado] = useState('1');
  const [idusuario, setIDUsuario] = useState('');
  const [idestabelecimento, setIDEstabelecimento] = useState('');
  const [nomesorteado, setNomeSorteado] = useState('');
  const [nomeestabelecimento, setNomeEstabelecimento] = useState('');
  const [idsorteio, setIDSorteio] = useState('');
  const [dencampanha, setDenCampanha] = useState('');
  

  const sortearNumero = () => {
    if(idcampanha<0){
      setMensagem('Selecione uma campanha')
      return
    }
    setSorteando(true);
    setTimeout(() => {
      //const numero = Math.floor(Math.random() * 500) + 1;
      api.get(`${config.ADM_SORTEIO}/${idcampanha}`)
      .then(response => {
        setSorteando(false);
        setSorteado(true);
        if(response.status === 200){
           setIDSorteado(response.data.idsorteado);
           setIDSorteio(response.data.id_sorteio);
           setIDUsuario(response.data.id_usuario);
           setIDEstabelecimento(response.data.id_estabelecimento);
           setNomeEstabelecimento(response.data.nom_estabecimento);
           setNomeSorteado(response.data.nom_usuario);
           setDenCampanha(`${response.data.den_campanha}`)
        }
      })
    }, 2000); // Tempo de espera para simular o sorteio
  };
  

  function setCampanha(e){    
    setIDCampanha(e);
  }

  useEffect (()=>{
    setLoading(true);
    api.get(config.ADM_CAMPANHA)
    .then(response => {
       setLoading(false);
        if (response.status === 200){
            const l = [];
            l.push({
              "id_campanha": -1,
              "den_campanha": "Selecione a campanha",
              })
              l.push({
                "id_campanha": getAno(),
                "den_campanha": `Todas as campanhas de  ${getAno()}`,
                })
            l.push(...response.data)
            setLCampanhas(l);
        }
    });
    setNumeroSorteado('SORTEANDO');
    setSorteado(false);
  },[props.isOpen])


  return <EDITMODAL
    programa={'SORTEIO'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={props.onRequestClose}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
    <div className="row">
    { !sorteado ? <>    
        <div className="col-md-6">
          <SELECT
          key={'SELECIONACAMPANHA'}
          name={'SELECIONACAMPANHA'}
          SalvaSelect={setCampanha}
          value={idcampanha}
          chave={'id_campanha'}
          descricao={'den_campanha'}
          aoptions={lcampanhas}
          //retornoe={true}
          />
      </div>
      <div className="sorteio-container">    
        <div className={`numero-sorteado ${sorteando ? "sorteando" : ""}`}>
          {numeroSorteado}
        </div>
        <button onClick={sortearNumero} disabled={sorteando}>
          Sortear
        </button>
      </div>
     </>
     :<>
     <div className="form-group row">
     <label for="Cupom" className="col-sm-2 col-form-label">Cupom</label>
     <div className="col-sm-10">
         <input type="text" value={idsorteio} className="form-control" id="Cupom" />
     </div>
    </div>
    <div className="form-group row">
        <label for="Nome" className="col-sm-2 col-form-label">Nome</label>
        <div className="col-sm-10">
            <input type="text" value={nomesorteado} className="form-control" id="Nome"  />
        </div>
    </div>
    <div className="form-group row">
        <label for="Estabelecimento" className="col-sm-2 col-form-label">Estabelecimento</label>
        <div className="col-sm-10">
            <input type="text" value={nomeestabelecimento} className="form-control" id="Estabelecimento" />
        </div>
    </div>  
    <div className="form-group row">
        <label for="Campanha" className="col-sm-2 col-form-label">Campanha</label>
        <div className="col-sm-10">
            <input type="text" value={dencampanha} className="form-control" id="Campanha" />
        </div>
    </div>   
    
    </>
     }

  </div>
  </EDITMODAL>
  
}

export default AdminSORTEIO;