import axios from 'axios';
import config from 'config';

const auth = config.BASICAPI;
const apibasic = axios.create({          
    baseURL: config.BASEAPI,  
    auth: {
        username: auth,
        password: auth
    }
});

export default apibasic;