import './style.css';

{/*
       exemplo
<RADIO
                    disabled={!admin}
                    chave={'inputIESCampanha'}
                    avalores={['S', 'N']}
                    alabel={['Sim', 'Não']}
                    setValor={setIESCampanha}
                    valor={iescampanha}
                  />
       
*/}

function RADIO(props){
   function setvalor(e){
       const index = props.index??-1;
       if(index>=0){
            props.setValor(e,index);
       }else{
           props.setValor(e);   
       }
   }
    return <div className="radio-control form-control"    
                id={props.chave} 
                onChange={(e)=>setvalor(e.target.value)}>
              {                                        
              props.avalores.map((o, index) => {
                     return <div key={`r${index}`}className="form-check form-check-inline">                                           
                            <input className="form-check-input"
                                   type="radio" 
                                   name={"flexRadioDefault"+props.chave}
                                   id={"flexRadioDefault1"+props.chave}
                                   key={props.chave+o}
                                   onChange={(e)=>setvalor(e.target.value)}
                                   value={o}
                                   checked={o==props.valor}
                                   disabled={props.disabled}
                                   />
                            <label className="form-check-label" 
                                   htmlFor={"flexRadioDefault"+props.chave}>
                                   {props.alabel[index]}
                            </label>
                            </div>
              })
              }
              </div>
}

export default RADIO;