import config from "config"
import api from "services/api";

  const getIPAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  };

  const getOSVersion = () => {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown OS";
  
    if (userAgent.indexOf("Win") !== -1) os = "Windows";
    if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
    if (userAgent.indexOf("X11") !== -1) os = "UNIX";
    if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  
    return os;
  };

 export const salvaPushNotification = async (atoken) => {
    const dados = {
        'token' : atoken,
        'ies_promocao' : 'S',
        'ies_live':'S',
        'ies_sorteio': 'S',
        'device':'WEB',
        'os_version':getOSVersion(),
        'ip': await getIPAddress()
    }
   api.post(`${config.USUARIOS}/salvatoken`, dados);
 }