import React, { useState, useEffect } from 'react';
import api from 'services/api';
import config from 'config';
import DOMPurify from 'dompurify';
import POPUP from '../popup';


const AvisoPopup = () => {
    const [avisos, setAvisos] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const getAvisos = async () => {
        try {
            const response = await api.get(config.AVISOS); // Usa api.get com a URL da config
            const data = response.data;

            // Verifica se há avisos na resposta
            if (data && data.length > 0) {
                return data;
            }

            return [];
        } catch (error) {
            console.error('Erro ao buscar avisos:', error);
            return [];
        }
    };

    useEffect(() => {
        const fetchAvisos = async () => {
            const avisosFromServer = await getAvisos();
            if (avisosFromServer.length > 0) {
                setAvisos(avisosFromServer);
                setShowPopup(true);
            }
        };

        fetchAvisos();
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };
    const formatTextWithNewlines = (text) => {
        // Substituir quebras de linha (\n) por <br />
        const formattedText = text.replace(/\n/g, '<br />');
                                 
        // Limpar o HTML com DOMPurify
        return DOMPurify.sanitize(formattedText);
    };

    return (
        showPopup && (
            <POPUP
            trigger={showPopup}
            setTrigger={setShowPopup}>
            <div className="popup-aviso">
                <div className="popup-aviso-content">
                    <div className="notification-list">
                        <h2>Você tem novos avisos</h2>
                        {avisos.map((n, index) => {
                            return <div key={`nof${index}`} className='notf-container'>
                           
                            <div className='notf-content'>
                            <div className='notf-imageContainer'>
                                <img src={n.image} alt="Notificação" className="notf-image" />
                            </div>
                                <h3>{n.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html:  formatTextWithNewlines(n.body) }} className="popup-text"></p>
                            </div>
                            <div className='notf-actions'>
                                
                            </div>
                        </div>
                        })
                        }
                        <button onClick={closePopup}>Fechar</button>
                    </div>
                </div>
            </div>
            </POPUP>
        )
    );
};

export default AvisoPopup;
