import api from "services/api";
import config from "config";

export const getNotificacao = async () =>{
    try {
        const response = await api.get(`${config.USUARIOS}/notificacao`);
        console.log(Number.isInteger(response.data.length) ? response.data.length : 0)
        return Number.isInteger(response.data.length) ? response.data.length : 0;

    } catch (error) {
        console.error('Error fetching notifications:', error);
        return 0;
    }    
}