import { useNavigate } from 'react-router-dom';

function LinkLogin() {
    const navigete = useNavigate();
    function Login() {
        navigete('/login');
    }
    return <div className="row  mt-5">
            <div className="col-12 btnselecionacidade"
                onClick={() => Login()}>
                <i className="fas fa-arrow-left"></i>voltar ao Login
            </div>
    </div>
}

export default LinkLogin;