import { Link } from 'react-router-dom';
import { formatDate } from '../formatos';
import IMAGEM from '../imagem';
import './style.css';

// function Campanha(props){
//     return <div key={props.key}>
//                 <Link to={props.link}>
//                     <IMAGEM src={props.banner} width={800} />                
//        </Link>
//     </div>
// }

// export default Campanha;

const Campanha = ({ descricao, banner, inicio, fim, link }) => {
    return (
      <div className="campanha">
        <img src={banner} alt={descricao} className="d-block custom-img" />
        <div className="carousel-caption">
          <h3>{descricao}</h3>
          <p>Início: {inicio} - Fim: {fim}</p>
          <a href={link} className="btn btn-primary">Saiba mais</a>
        </div>
      </div>
    );
  };
  
  export default Campanha;