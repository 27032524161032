import { useEffect, useState } from "react";
import NAVBAR from "components/navbar";
import api from 'services/api';
import CustomProgressBar from "components/progressbar";
import QRCode from "react-qr-code";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import './style.css';
import { getnome } from "functions/gettabconfig";
import { formatNumber } from "components/formatos";
import config from "config";



function MeuHome() {

    const [lextrato, setLExtrato] = useState([]);
    const [lcampanha, setLCampanha] = useState([]);
    const [lcampestab, setLCampEstab] = useState([]);
    const [lsorteio, setLSorteio] = useState(['123465', '556666', '6898752']);
    const [ldesconto, setLDesconto] = useState(['1.90', '0.50', '0.69']);
    const [progress, setProgress] = useState(100);
    const [progress2, setProgress2] = useState(100);
    var tsort = 0.0;
    var tcash = 0.0;

    useEffect(() => {
        api.get(config.EXTRATO)
            .then(response => {
                setLExtrato(response.data);
                tcash = response.data.filter((x) => x.ativocb === 'A').reduce((a, e) => a + e.saldo_cashback, 0);
                tsort = (response.data.filter((x) => x.ativost === 'A').reduce((a, e) => a + e.saldo_sorteio, 0));
                setProgress(tsort);
                setProgress2(tcash);
            });
        // Listar Cupons
        // Listar Vale Descontos         
    }, [])

    return <div>
        <NAVBAR />
        <h1 className="ms-2">Você no Grupo de Empresas Amigas</h1>
        <div className="container identificacao">
            <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                    <label className="d-block text-center">Sua Identificação para compras</label>
                    <div className="d-flex justify-content-center">
                        <QRCode
                            value={getnome()}
                        />
                    </div>
                    <label className="d-block text-center">{getnome()}</label>
                </div>
            </div>
        </div>
        <div className="grafico">
            <div className="row mb-4">
                <div className="col-md-12">
                    <label>Pontos Acumulados</label>
                    <CustomProgressBar 
                       progress={progress}
                       label={Math.floor(progress/100).toString()} />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12">
                    <label>Cash Desconto</label>
                    <CustomProgressBar 
                       progress={progress2}
                       label={formatNumber(progress2,2)} />
                </div>
            </div>

            <h2>Cupoms de sorteio</h2>
            <div className="row mb-4">
                {lsorteio.map((e, index) => {
                    return <div className="col-md-2" key={`cp${index}`}>
                        <div className="card" >
                        <div className="cadrd-header">Seus cupom {e}</div>
                        <div className="card-body">
                            <QRCode
                                size={180}
                                value={base64_encode(e)}
                            />                            
                        </div>
                        <div className="card-footer">Validade 99/99/9999</div>
                    </div>
                    </div>
                })}
            </div>

            <h2>Descontos</h2>
            <div className="row mb-4">
                {
                    ldesconto.map((e, index) => {
                        return <div className="col-md-2" key={`ds${index}`}>
                        <div className="card" >
                        <div className="cadrd-header">Desconto {e}</div>
                        <div className="card-body">
                            <QRCode
                                size={180}
                                value={base64_encode(e)}
                            />                            
                        </div>
                        <div className="card-footer">Validade 99/99/9999</div>
                    </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default MeuHome;