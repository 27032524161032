import { useEffect, useState } from 'react';
import NAVBAR from 'components/navbar';
import config from 'config';
import api from 'services/api';
import './style.css';
import { formatDate } from 'components/formatos';

function NotificacaoPage() {
  const [anotificao, setANotificacao] = useState([]);

  function Carrega(){
    api.get(`${config.USUARIOS}/notificacao`)
    .then(response => {
      setANotificacao(response.data);
    });
  }

  useEffect(() => {
     Carrega();
  }, [])

  function DeletaNotificao(id){
    api.delete(`${config.USUARIOS}/notificacao/${id}`)
    .then( response => {
      Carrega();
    });
  }
  
  return <>
    <NAVBAR />
    <div className='mt-page'>
      <div className="notification-list">
        {
          anotificao.map((n, index) => {
            return <div key={`nof${index}`} className='notf-container'>
              <div className='notf-imageContainer'>
                <img src={n.image} alt="Notificação" className="notf-image" />
              </div>
              <div className='notf-content'>
                <h3>{n.title}</h3>
                <p>{n.body}</p>              
                <footer className='notf-footer'>Mensagem envia em {formatDate(n.dat_recece,'dd/mm/yyyy hh:mm')}</footer>
              </div>
              <div className='notf-actions'>
              <button className="btn btn-outline-danger ms-2"
          type='button' onClick={()=>DeletaNotificao(n.id_message)}>
          <span><i className="fas fa-times"></i></span></button>
                </div>
            </div>
          })}
      </div>
    </div>

  </>
}

export default NotificacaoPage;