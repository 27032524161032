import { useEffect, useState } from "react";

function MESSAGE({ msg }) {
    const [msgVisible, setMSGVisible] = useState(false);
    const [prevMsg, setPrevMsg] = useState('');

    useEffect(() => {
        if (!msg) {
            setMSGVisible(false);
        } else {
            if (msg !== prevMsg) {
                setPrevMsg(msg);
                setMSGVisible(true);
                console.error(msg);
                const timer = setTimeout(() => {
                    setMSGVisible(false);
                }, 5000);
                return () => clearTimeout(timer);
            } else {                
                setMSGVisible(false);
                setTimeout(() => {
                    setMSGVisible(true);
                }, 0);
            }
        }
    }, [msg, prevMsg]);

    return (
        <>
            {msgVisible && (
                <div className="alert alert-danger d-flex justify-content-between align-items-center mt-2" role="alert">
                <div className="flex-grow-1">
                    {msg}
                </div>
                <button 
                    type="button" 
                    className="btn-close" 
                    aria-label="Close" 
                    onClick={() => setMSGVisible(false)}
                ></button>
            </div>
            )}
        </>
    );
}

export default MESSAGE;
