import POPUP from '../popup';
import './style.css';
/*
<DLGMESSAGE
  trigger={pp}
  setTrigger={setP}
  onConfirma={}
  title={''}
  message={}

*/

function DLGMESSAGE(props){

  function CloseOK(){
    props.setTrigger(false);
  }

  function OK(){
      props.onConfirma();
      CloseOK();
  }  
  return <POPUP
            trigger={props.trigger}
            setTrigger={CloseOK}
            popupinner={'dialogconfima-popup-inner'}>
            <div className="form-control">
              <div className="row">                    
                <h3>{props.title}</h3>                    
                <hr/>                    
                <h6>{props.message}</h6>
                <div className="modal-footer justify-content-evenly">
                  <div className="col-3">
                    <div className="btn btn-primary" onClick={()=>OK()}>OK</div>
                  </div>                      
                </div>
              </div>
            </div>
            </POPUP>
}

export default DLGMESSAGE;