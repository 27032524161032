import { NumericFormat } from 'react-number-format';
import './style.css';
import { formatNumber } from 'components/formatos';

function FLOATINPUT(props){
      function handleNumber(input){
        const iindex = props.index??-1;
        const v = parseFloat(input.toString().replace(',','.'));
        if (iindex >= 0 ){
           props.setNumber(v, props.index)
        } else { 
          props.setNumber(v);
        }
      }
      const dS=props.decimalScale??2;
      
      return <NumericFormat  className="form-control  input-rigth"
             // defaultValue={props.number.toLocaleString("pt-br", {minimumFractionDigits: dS})}
              value={props.number}
              valueIsNumericString={true}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={dS}
              placeholder="Valor"
              allowNegative={props.allowNegative??false}
              onValueChange={(values) => {handleNumber(values.value)}} 
              renderText={(value)=>formatNumber(value,dS)}
              isAllowed={props.isAllowed}
      />
}                                            
export default FLOATINPUT;