import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './style.css';
import Logo from 'assets/ActusIcone2025x1024.png';
import Fundo from 'assets/fundo-login.png';
import SaltPassword from 'services/md5';
import Footer from 'components/footer';
import apibasic from 'services/apibasic';

function LoginParceiro(){

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);
    const navigete = useNavigate();

    function ProcessaLogin(e){
       e.preventDefault();

       if(email.length===0){
        setSucesso('E');
        return false;
       }

       if(senha.length===0){
        setSucesso('E');
        return false;
       }
       
       setSucesso('');
       setLoading(true);

       apibasic.post('/v1/parceiro/login',{
           email: email,
           senha: SaltPassword(senha)
       }).then(response => {
           if(response.data.parceiro ===true){
            localStorage.setItem('eamigaconfig',JSON.stringify(
                response.data
                ));
            setLoading(false);
            setSucesso('S');
            navigete('/parceiro/vender');
           } else {
            setSucesso('N');
            setLoading(false);
           }
       }) 
       .catch(err => {
           setLoading(false);
           setSucesso('N');           
       })
    }
    useEffect(()=>{
       // localStorage.removeItem('eamigaconfig');       
    },[])

    return <div>            
             <div className="row">
             <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
                <form className="form-login mt-5" onSubmit={(e)=>ProcessaLogin(e)}>
                <img className="mb-3" src={Logo} alt="" width={100} />
                    <h2 className="mb-3">PARCEIRO</h2>
                    <div className="form-floating mb-3">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} 
                         className="form-control" id="emailparceito" placeholder="e-mail"/>
                        <label htmlFor="emailparceito">E-mail</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="password" onChange={(e) => setSenha(e.target.value)} 
                         className="form-control" id="parceiropassord" placeholder="Senha"/>
                        <label htmlFor="parceiropassord">Senha</label>
                    </div> 
                    <button className="w-100 btn btn-lg btn-danger" 
                            disabled={loading} 
                            type='submit'
                            onClick={ProcessaLogin}>
                            {loading ? <div>
                                        <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                        <span className="ms-2">Enviando...</span>
                                       </div>
                                       :
                                        <span className="ms-2">Acessar</span>}
                    </button>
                    {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">e-mail ou senha invalidos</div> : null }
                    {sucesso === 'E' ? <div className="alert alert-danger mt-2" role="alert">e-mail ou senha vazios</div> : null }                 
                </form>
            </div>

            <div className="col-sm-6 px-0 d-none d-sm-block">
                <img className="background-login" src={Fundo} alt="Opnet Mais" />
            </div>
        <Footer />
    </div>
    </div>
}

export default LoginParceiro;